<template>
    <AModalContent :show-close="false" class="harvest-limit-modal">
        <IAlarm width="50" class="harvest-limit-modal-icon" />
        <div class="harvest-limit-modal-title">
Attention!
</div>
        <div class="harvest-limit-modal-subtitle">
            <span>{{ unharvestedTokensCount }}</span> out of
            <span>{{ harvestTokensCount }}</span>
            token{{ harvestTokensCount > 1 ? 's' : '' }} will not be harvested.
        </div>
        <div class="harvest-limit-modal-description">
            You don't have enough BAP/BOTB assets to harvest all Community Tokenized Assets.
            <br>
            <br>Guardrails are described
            <a
                href="https://www.bullsandapesproject.com/get-started#guardrails"
                target="_blank"
            >here</a> to unlock full harvesting.
        </div>

        <div class="harvest-limit-modal-buttons">
            <a
                href="javascript:void(0)"
                class="harvest-limit-modal-button harvest-limit-modal-button-cancel"
                @click.prevent="cancel"
            >Cancel</a>
            <a
                v-if="!hasSavedHarvest"
                href="javascript:void(0)"
                class="harvest-limit-modal-button harvest-limit-modal-button-confirm"
                @click.prevent="calibrate"
            >Proceed</a>
            <a
                v-if="hasSavedHarvest"
                href="javascript:void(0)"
                class="harvest-limit-modal-button harvest-limit-modal-button-confirm"
                @click.prevent="useSavedHarvest"
            >Harvest saved tokens</a>
        </div>
        <a
            v-if="hasSavedHarvest"
            href="javascript:void(0)"
            class="harvest-limit-modal-button harvest-limit-modal-button-change"
            @click.prevent="calibrate"
        >Change Tokens to Harvest</a>
        <div class="harvest-limit-modal-harvest-info">
            You will receive
            <span
                class="harvest-limit-modal-harvest-info-meth-harvestable"
            >+{{ harvestableMeth }} METH</span>
            instead of
            <span
                class="harvest-limit-modal-harvest-info-meth-all"
            >{{ harvestableMethAll }} METH</span>
        </div>
    </AModalContent>
</template>
<script setup>
import { TokenType } from "@/config/token-types";
import { useHarvestLimitStore } from "@/store/harvest-limit";
import { computed } from "vue";
import { CollectionLayouts, useCollectionLayout } from "../Collection/useCollectionLayout";
import AModalContent from "../Modal/AModalContent.vue";
import IAlarm from "@/assets/img/info.svg?inline"
import { web3 } from "@/web3";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useMethBankStore } from "@/store/meth-bank";
import { getApeBonus } from "@/helpers/get-ape-bonus";
import { getAttachedApe } from "@/helpers/get-attached-ape";

const props = defineProps({ items: { type: Object, default: () => ({}) }, onApprove: Function, onCancel: Function })
const emit = defineEmits(['close', 'init'])
emit('init', { closeOnOverlay: false })
const harvestLimitStore = useHarvestLimitStore()
const collections = computed(() => {
    return Object.keys(props.items).map(key => {
        return {
            name: key,
            items: props.items[key]
        }
    })
})
const unharvestedTokensCount = computed(() => {
    const harvestTokensCount = useHarvestLimitStore().harvestPartnersCount;
    const canHarvestTotal = useHarvestLimitStore().allowedHarvestPartnersCount;

    return harvestTokensCount - canHarvestTotal;

})
const harvestTokensCount = computed(() => useHarvestLimitStore().harvestPartnersCount)
const calibrate = () => {
    emit('close')
    const defaultSelection = Object.values(props.items).reduce((arr, collection) => arr.concat(collection), [])

    useCollectionLayout().switchCollectionLayout(CollectionLayouts.harvestLimit, {
        defaultSelection,
        selectLimit: harvestLimitStore.allowedHarvestPartnersCount,
        onCancel: () => {
            props.onCancel && props.onCancel()
        },
        onApprove: (selection) => {
            const approvedTokens = selection.reduce((partnersTokens, token) => {
                const partnerAddress = web3.utils.toChecksumAddress(
                    token.token_address
                );
                if (!partnersTokens[partnerAddress])
                    partnersTokens[partnerAddress] = [];
                partnersTokens[partnerAddress].push(token);
                return partnersTokens;
            }, {});
            props.onApprove && props.onApprove(approvedTokens)
        }
    })
}
const cancel = () => {
    props.onCancel()
    emit('close')

}
const useSavedHarvest = () => {
    props.onApprove && props.onApprove(harvestLimitStore.harvestItemsPopulated)
    emit('close')
}
const harvestableMeth = computed(() => {
    let partnersTotal = 0;
    const partners = useWalletCollectionStore().partners
    Object.keys(partners).forEach((key) => {
        partners[key].forEach((token) => {
            const bonus = getAttachedApe(token) ? getApeBonus(token) : 0;
            partnersTotal += (token.harvestInfo.harvestPerDay || 0) + bonus;
        });
    });
    const harvestWithoutPartners = useMethBankStore().harvestableMeth - partnersTotal
    const limitedPartnersHarvestable = Object.values(props.items).reduce((sum, arr) => {
        const harvestable = arr.reduce((s, item) => s + ((item.harvestInfo?.harvestPerDay || 0) + (getAttachedApe(item) ? getApeBonus(item) : 0)), 0)
        return sum + harvestable
    }, 0)
    return harvestWithoutPartners + limitedPartnersHarvestable
})
const harvestableMethAll = computed(() => {
    return useMethBankStore().harvestableMeth
})

const hasSavedHarvest = computed(() => {
    return harvestLimitStore.isCollectionSame &&
        Object.keys(harvestLimitStore.harvestItemsPopulated).length &&
        harvestLimitStore.isSameLimits
})
</script>
<style lang="scss">
.harvest-limit-modal {
    background-color: var(--gray-light);
    padding: 20px;
    box-sizing: border-box;
    width: 420px;
    max-width: 90%;
    text-align: center;
    border: 1px solid var(--border);
    &-title {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 5px;
    }
    &-subtitle {
        font-size: 18px;
        margin-bottom: 10px;
        color: var(--red);
        span {
            font-weight: bold;
        }
    }
    &-description {
        a {
            color: var(--primary);
        }
    }
    &-buttons {
        display: flex;
        align-items: stretch;
        justify-content: center;
        margin-top: 20px;
    }
    &-button {
        flex: 1;
        padding: 10px 15px;
        border-radius: 5px;
        color: #fff;
        text-decoration: none;
        transition: 0.2s;
        font-weight: bold;
        position: relative;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        align-items: center;
        &:hover {
            filter: brightness(0.8);
        }
        &-confirm {
            background-color: var(--green);
        }
        &-cancel {
            background-color: var(--red);
            margin-right: 10px;
        }
        &-change {
            width: 100%;
            margin-top: 8px;
            border: 1px solid var(--border);
            &:hover {
                background-color: var(--gray-light);
            }
        }
    }
    &-harvest-info {
        margin-top: 8px;
        font-size: 14px;

        &-meth-all {
            color: var(--primary);
        }
        &-meth-harvestable {
            color: var(--green);
        }
        span {
            font-size: 16px;
            font-weight: bold;
        }
    }
}
</style>